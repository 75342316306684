"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

var _react = _interopRequireDefault(require("react"));

var _burger = _interopRequireDefault(require("../burger"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var TopNavBurger = (0, _styledBase["default"])("div", {
  target: "e1c4rpz10",
  label: "TopNavBurger"
})(process.env.NODE_ENV === "production" ? {
  name: "ky7yrb",
  styles: "margin:0;padding:0;border:0;vertical-align:baseline;box-sizing:border-box;cursor:pointer;svg{position:absolute;z-index:100;left:20px;top:20px;height:20px;width:18px;cursor:pointer;}@media (min-width:768px){display:none;}"
} : {
  name: "ky7yrb",
  styles: "margin:0;padding:0;border:0;vertical-align:baseline;box-sizing:border-box;cursor:pointer;svg{position:absolute;z-index:100;left:20px;top:20px;height:20px;width:18px;cursor:pointer;}@media (min-width:768px){display:none;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL3RvcC1uYXYvYnVyZ2VyLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUsrQiIsImZpbGUiOiIuLi8uLi8uLi9zcmMvY29tcG9uZW50cy90b3AtbmF2L2J1cmdlci5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tIFwicmVhY3RcIjtcclxuaW1wb3J0IHN0eWxlZCBmcm9tIFwiQGVtb3Rpb24vc3R5bGVkXCI7XHJcblxyXG5pbXBvcnQgQnVyZ2VySWNvbiBmcm9tIFwiLi4vYnVyZ2VyXCI7XHJcblxyXG5jb25zdCBUb3BOYXZCdXJnZXIgPSBzdHlsZWQuZGl2YFxyXG4gIG1hcmdpbjogMDtcclxuICBwYWRkaW5nOiAwO1xyXG4gIGJvcmRlcjogMDtcclxuICB2ZXJ0aWNhbC1hbGlnbjogYmFzZWxpbmU7XHJcbiAgYm94LXNpemluZzogYm9yZGVyLWJveDtcclxuICBjdXJzb3I6IHBvaW50ZXI7XHJcblxyXG4gIHN2ZyB7XHJcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XHJcbiAgICB6LWluZGV4OiAxMDA7XHJcbiAgICBsZWZ0OiAyMHB4O1xyXG4gICAgdG9wOiAyMHB4O1xyXG4gICAgaGVpZ2h0OiAyMHB4O1xyXG4gICAgd2lkdGg6IDE4cHg7XHJcbiAgICBjdXJzb3I6IHBvaW50ZXI7XHJcbiAgfVxyXG5cclxuICBAbWVkaWEgKG1pbi13aWR0aDogNzY4cHgpIHtcclxuICAgIGRpc3BsYXk6IG5vbmU7XHJcbiAgfVxyXG5gO1xyXG5cclxuY29uc3QgQnVyZ2VyID0gKHsgb25DbGljayB9KSA9PiAoXHJcbiAgPFRvcE5hdkJ1cmdlciBvbkNsaWNrPXtvbkNsaWNrfT5cclxuICAgIDxCdXJnZXJJY29uPjwvQnVyZ2VySWNvbj5cclxuICA8L1RvcE5hdkJ1cmdlcj5cclxuKTtcclxuXHJcbmV4cG9ydCBkZWZhdWx0IEJ1cmdlcjtcclxuIl19 */"
});

var Burger = function Burger(_ref) {
  var onClick = _ref.onClick;
  return _react["default"].createElement(TopNavBurger, {
    onClick: onClick
  }, _react["default"].createElement(_burger["default"], null));
};

var _default = Burger;
exports["default"] = _default;