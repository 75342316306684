"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var SubHeadlineIcon = (0, _styledBase["default"])("span", {
  target: "e1jrcsy00",
  label: "SubHeadlineIcon"
})(process.env.NODE_ENV === "production" ? {
  name: "1alqhcn",
  styles: "padding-right:10px;padding-top:3px;"
} : {
  name: "1alqhcn",
  styles: "padding-right:10px;padding-top:3px;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL3N1Yi1oZWFkbGluZS1pY29uLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVtQyIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9zdWItaGVhZGxpbmUtaWNvbi5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xyXG5cclxuY29uc3QgU3ViSGVhZGxpbmVJY29uID0gc3R5bGVkLnNwYW5gXHJcbiAgcGFkZGluZy1yaWdodDogMTBweDtcclxuICBwYWRkaW5nLXRvcDogM3B4O1xyXG5gO1xyXG5cclxuZXhwb3J0IGRlZmF1bHQgU3ViSGVhZGxpbmVJY29uO1xyXG4iXX0= */"
});
var _default = SubHeadlineIcon;
exports["default"] = _default;