"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _drawer = _interopRequireDefault(require("../drawer"));

var _item = _interopRequireDefault(require("./item"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var MobileMenu = function MobileMenu(_ref) {
  var items = _ref.items,
      isOpen = _ref.isOpen,
      onClose = _ref.onClose;
  return _react["default"].createElement(_drawer["default"], {
    isOpen: isOpen,
    onClose: onClose
  }, items.map(function (_ref2) {
    var label = _ref2.label,
        url = _ref2.url,
        isExternal = _ref2.isExternal;
    return _react["default"].createElement(_item["default"], {
      to: url,
      key: url,
      isExternal: isExternal
    }, label);
  }));
};

MobileMenu.propTypes = {
  items: _propTypes["default"].instanceOf(Array).isRequired,
  isOpen: _propTypes["default"].bool
};
MobileMenu.defaultProps = {
  isOpen: false
};
var _default = MobileMenu;
exports["default"] = _default;