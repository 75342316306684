"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var MainNavMenuItem = (0, _styledBase["default"])("a", {
  target: "e5r9spr0",
  label: "MainNavMenuItem"
})(process.env.NODE_ENV === "production" ? {
  name: "ar5o4c",
  styles: "font-weight:600;letter-spacing:0.1px;color:#fff;cursor:pointer;transition:all 0.3s;position:relative;flex-shrink:0;height:45px;display:flex;align-items:center;padding-left:20px;font-size:16px;text-decoration:none;box-sizing:border-box;font-family:\"Open Sans\",sans-serif;@media (min-width:768px){padding-left:0;}"
} : {
  name: "ar5o4c",
  styles: "font-weight:600;letter-spacing:0.1px;color:#fff;cursor:pointer;transition:all 0.3s;position:relative;flex-shrink:0;height:45px;display:flex;align-items:center;padding-left:20px;font-size:16px;text-decoration:none;box-sizing:border-box;font-family:\"Open Sans\",sans-serif;@media (min-width:768px){padding-left:0;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL3N1Yi1uYXYvbWVudS1pdGVtLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVnQyIsImZpbGUiOiIuLi8uLi8uLi9zcmMvY29tcG9uZW50cy9zdWItbmF2L21lbnUtaXRlbS5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xyXG5cclxuY29uc3QgTWFpbk5hdk1lbnVJdGVtID0gc3R5bGVkLmFgXHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxuICBsZXR0ZXItc3BhY2luZzogMC4xcHg7XHJcbiAgY29sb3I6ICNmZmY7XHJcbiAgY3Vyc29yOiBwb2ludGVyO1xyXG4gIHRyYW5zaXRpb246IGFsbCAwLjNzO1xyXG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcclxuICBmbGV4LXNocmluazogMDtcclxuICBoZWlnaHQ6IDQ1cHg7XHJcbiAgZGlzcGxheTogZmxleDtcclxuICBhbGlnbi1pdGVtczogY2VudGVyO1xyXG4gIHBhZGRpbmctbGVmdDogMjBweDtcclxuICBmb250LXNpemU6IDE2cHg7XHJcbiAgdGV4dC1kZWNvcmF0aW9uOiBub25lO1xyXG4gIGJveC1zaXppbmc6IGJvcmRlci1ib3g7XHJcbiAgZm9udC1mYW1pbHk6IFwiT3BlbiBTYW5zXCIsIHNhbnMtc2VyaWY7XHJcblxyXG4gIEBtZWRpYSAobWluLXdpZHRoOiA3NjhweCkge1xyXG4gICAgcGFkZGluZy1sZWZ0OiAwO1xyXG4gIH1cclxuYDtcclxuXHJcbmV4cG9ydCBkZWZhdWx0IE1haW5OYXZNZW51SXRlbTtcclxuIl19 */"
});
var _default = MainNavMenuItem;
exports["default"] = _default;