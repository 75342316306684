"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var DialogBoxFooter = (0, _styledBase["default"])("div", {
  target: "e1e4pmoq0",
  label: "DialogBoxFooter"
})(process.env.NODE_ENV === "production" ? {
  name: "ha9isz",
  styles: "@media (min-width:600px){}font-size:20px;line-height:1.1;padding-bottom:20px;margin-bottom:24px;font-weight:500;display:block;text-align:left;}"
} : {
  name: "ha9isz",
  styles: "@media (min-width:600px){}font-size:20px;line-height:1.1;padding-bottom:20px;margin-bottom:24px;font-weight:500;display:block;text-align:left;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2RpYWxvZy1ib3gtZm9vdGVyLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVrQyIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9kaWFsb2ctYm94LWZvb3Rlci5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xyXG5cclxuY29uc3QgRGlhbG9nQm94Rm9vdGVyID0gc3R5bGVkLmRpdmBcclxuICBAbWVkaWEgKG1pbi13aWR0aDogNjAwcHgpIHtcclxuICB9XHJcbiAgZm9udC1zaXplOiAyMHB4O1xyXG4gIGxpbmUtaGVpZ2h0OiAxLjE7XHJcbiAgcGFkZGluZy1ib3R0b206IDIwcHg7XHJcbiAgbWFyZ2luLWJvdHRvbTogMjRweDtcclxuICBmb250LXdlaWdodDogNTAwO1xyXG4gIGRpc3BsYXk6IGJsb2NrO1xyXG4gIHRleHQtYWxpZ246IGxlZnQ7XHJcbn1cclxuYDtcclxuXHJcbmV4cG9ydCBkZWZhdWx0IERpYWxvZ0JveEZvb3RlcjtcclxuIl19 */"
});
var _default = DialogBoxFooter;
exports["default"] = _default;