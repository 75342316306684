"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var TableHeaderTr = (0, _styledBase["default"])("tr", {
  target: "e1kfiq560",
  label: "TableHeaderTr"
})(process.env.NODE_ENV === "production" ? {
  name: "1pv0f68",
  styles: "padding:8px;background-color:#ffffff;font-size:14px;font-weight:600;line-height:1.71;color:#4d4d4d;"
} : {
  name: "1pv0f68",
  styles: "padding:8px;background-color:#ffffff;font-size:14px;font-weight:600;line-height:1.71;color:#4d4d4d;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL3RhYmxlLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUcrQiIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy90YWJsZS5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xyXG5pbXBvcnQgUmVhY3QgZnJvbSBcInJlYWN0XCI7XHJcblxyXG5jb25zdCBUYWJsZUhlYWRlclRyID0gc3R5bGVkLnRyYFxyXG4gIHBhZGRpbmc6IDhweDtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmZmZmZmO1xyXG4gIGZvbnQtc2l6ZTogMTRweDtcclxuICBmb250LXdlaWdodDogNjAwO1xyXG4gIGxpbmUtaGVpZ2h0OiAxLjcxO1xyXG4gIGNvbG9yOiAjNGQ0ZDRkO1xyXG5gO1xyXG5cclxuY29uc3QgVGFibGVIZWFkZXJUZCA9IHN0eWxlZC50ZGBcclxuICBwYWRkaW5nOiA4cHg7XHJcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ZmZmZmZjtcclxuICBmb250LXNpemU6IDE0cHg7XHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxuICBsaW5lLWhlaWdodDogMS43MTtcclxuICBib3JkZXItYm90dG9tOiAxcHggc29saWQgIzExMTExMTtcclxuYDtcclxuXHJcbmNvbnN0IFJvb3QgPSBzdHlsZWQudGFibGVgXHJcbiAgcGFkZGluZzogOHB4O1xyXG4gIGJvcmRlci1yYWRpdXM6IDJweDtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmZmZmZmO1xyXG4gIGZvbnQtc2l6ZTogMTRweDtcclxuICBmb250LXdlaWdodDogNjAwO1xyXG4gIGxpbmUtaGVpZ2h0OiAxLjcxO1xyXG4gIGNvbG9yOiAjNGQ0ZDRkO1xyXG4gIG1hcmdpbi1sZWZ0OiBhdXRvO1xyXG4gIG1hcmdpbi1yaWdodDogYXV0bztcclxuYDtcclxuXHJcbmZ1bmN0aW9uIFRhYmxlKHsgY29sdW1ucywgY2hpbGRyZW4sIC4uLm90aGVyIH0pIHtcclxuICByZXR1cm4gKFxyXG4gICAgPFJvb3Qgey4uLm90aGVyfT5cclxuICAgICAgPHRoZWFkPlxyXG4gICAgICAgIDxUYWJsZUhlYWRlclRyPlxyXG4gICAgICAgICAge2NvbHVtbnMubWFwKChpdGVtLCBpbmRleCkgPT4gKFxyXG4gICAgICAgICAgICA8VGFibGVIZWFkZXJUZCBrZXk9e2luZGV4fT57aXRlbX08L1RhYmxlSGVhZGVyVGQ+XHJcbiAgICAgICAgICApKX1cclxuICAgICAgICA8L1RhYmxlSGVhZGVyVHI+XHJcbiAgICAgIDwvdGhlYWQ+XHJcbiAgICAgIDx0Ym9keT57Y2hpbGRyZW59PC90Ym9keT5cclxuICAgIDwvUm9vdD5cclxuICApO1xyXG59XHJcblxyXG5leHBvcnQgZGVmYXVsdCBUYWJsZTtcclxuIl19 */"
});
var TableHeaderTd = (0, _styledBase["default"])("td", {
  target: "e1kfiq561",
  label: "TableHeaderTd"
})(process.env.NODE_ENV === "production" ? {
  name: "1oeaxyu",
  styles: "padding:8px;background-color:#ffffff;font-size:14px;font-weight:600;line-height:1.71;border-bottom:1px solid #111111;"
} : {
  name: "1oeaxyu",
  styles: "padding:8px;background-color:#ffffff;font-size:14px;font-weight:600;line-height:1.71;border-bottom:1px solid #111111;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL3RhYmxlLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQVkrQiIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy90YWJsZS5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xyXG5pbXBvcnQgUmVhY3QgZnJvbSBcInJlYWN0XCI7XHJcblxyXG5jb25zdCBUYWJsZUhlYWRlclRyID0gc3R5bGVkLnRyYFxyXG4gIHBhZGRpbmc6IDhweDtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmZmZmZmO1xyXG4gIGZvbnQtc2l6ZTogMTRweDtcclxuICBmb250LXdlaWdodDogNjAwO1xyXG4gIGxpbmUtaGVpZ2h0OiAxLjcxO1xyXG4gIGNvbG9yOiAjNGQ0ZDRkO1xyXG5gO1xyXG5cclxuY29uc3QgVGFibGVIZWFkZXJUZCA9IHN0eWxlZC50ZGBcclxuICBwYWRkaW5nOiA4cHg7XHJcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ZmZmZmZjtcclxuICBmb250LXNpemU6IDE0cHg7XHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxuICBsaW5lLWhlaWdodDogMS43MTtcclxuICBib3JkZXItYm90dG9tOiAxcHggc29saWQgIzExMTExMTtcclxuYDtcclxuXHJcbmNvbnN0IFJvb3QgPSBzdHlsZWQudGFibGVgXHJcbiAgcGFkZGluZzogOHB4O1xyXG4gIGJvcmRlci1yYWRpdXM6IDJweDtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmZmZmZmO1xyXG4gIGZvbnQtc2l6ZTogMTRweDtcclxuICBmb250LXdlaWdodDogNjAwO1xyXG4gIGxpbmUtaGVpZ2h0OiAxLjcxO1xyXG4gIGNvbG9yOiAjNGQ0ZDRkO1xyXG4gIG1hcmdpbi1sZWZ0OiBhdXRvO1xyXG4gIG1hcmdpbi1yaWdodDogYXV0bztcclxuYDtcclxuXHJcbmZ1bmN0aW9uIFRhYmxlKHsgY29sdW1ucywgY2hpbGRyZW4sIC4uLm90aGVyIH0pIHtcclxuICByZXR1cm4gKFxyXG4gICAgPFJvb3Qgey4uLm90aGVyfT5cclxuICAgICAgPHRoZWFkPlxyXG4gICAgICAgIDxUYWJsZUhlYWRlclRyPlxyXG4gICAgICAgICAge2NvbHVtbnMubWFwKChpdGVtLCBpbmRleCkgPT4gKFxyXG4gICAgICAgICAgICA8VGFibGVIZWFkZXJUZCBrZXk9e2luZGV4fT57aXRlbX08L1RhYmxlSGVhZGVyVGQ+XHJcbiAgICAgICAgICApKX1cclxuICAgICAgICA8L1RhYmxlSGVhZGVyVHI+XHJcbiAgICAgIDwvdGhlYWQ+XHJcbiAgICAgIDx0Ym9keT57Y2hpbGRyZW59PC90Ym9keT5cclxuICAgIDwvUm9vdD5cclxuICApO1xyXG59XHJcblxyXG5leHBvcnQgZGVmYXVsdCBUYWJsZTtcclxuIl19 */"
});
var Root = (0, _styledBase["default"])("table", {
  target: "e1kfiq562",
  label: "Root"
})(process.env.NODE_ENV === "production" ? {
  name: "1h4s244",
  styles: "padding:8px;border-radius:2px;background-color:#ffffff;font-size:14px;font-weight:600;line-height:1.71;color:#4d4d4d;margin-left:auto;margin-right:auto;"
} : {
  name: "1h4s244",
  styles: "padding:8px;border-radius:2px;background-color:#ffffff;font-size:14px;font-weight:600;line-height:1.71;color:#4d4d4d;margin-left:auto;margin-right:auto;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL3RhYmxlLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQXFCeUIiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvdGFibGUuanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcclxuaW1wb3J0IFJlYWN0IGZyb20gXCJyZWFjdFwiO1xyXG5cclxuY29uc3QgVGFibGVIZWFkZXJUciA9IHN0eWxlZC50cmBcclxuICBwYWRkaW5nOiA4cHg7XHJcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ZmZmZmZjtcclxuICBmb250LXNpemU6IDE0cHg7XHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxuICBsaW5lLWhlaWdodDogMS43MTtcclxuICBjb2xvcjogIzRkNGQ0ZDtcclxuYDtcclxuXHJcbmNvbnN0IFRhYmxlSGVhZGVyVGQgPSBzdHlsZWQudGRgXHJcbiAgcGFkZGluZzogOHB4O1xyXG4gIGJhY2tncm91bmQtY29sb3I6ICNmZmZmZmY7XHJcbiAgZm9udC1zaXplOiAxNHB4O1xyXG4gIGZvbnQtd2VpZ2h0OiA2MDA7XHJcbiAgbGluZS1oZWlnaHQ6IDEuNzE7XHJcbiAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkICMxMTExMTE7XHJcbmA7XHJcblxyXG5jb25zdCBSb290ID0gc3R5bGVkLnRhYmxlYFxyXG4gIHBhZGRpbmc6IDhweDtcclxuICBib3JkZXItcmFkaXVzOiAycHg7XHJcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ZmZmZmZjtcclxuICBmb250LXNpemU6IDE0cHg7XHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxuICBsaW5lLWhlaWdodDogMS43MTtcclxuICBjb2xvcjogIzRkNGQ0ZDtcclxuICBtYXJnaW4tbGVmdDogYXV0bztcclxuICBtYXJnaW4tcmlnaHQ6IGF1dG87XHJcbmA7XHJcblxyXG5mdW5jdGlvbiBUYWJsZSh7IGNvbHVtbnMsIGNoaWxkcmVuLCAuLi5vdGhlciB9KSB7XHJcbiAgcmV0dXJuIChcclxuICAgIDxSb290IHsuLi5vdGhlcn0+XHJcbiAgICAgIDx0aGVhZD5cclxuICAgICAgICA8VGFibGVIZWFkZXJUcj5cclxuICAgICAgICAgIHtjb2x1bW5zLm1hcCgoaXRlbSwgaW5kZXgpID0+IChcclxuICAgICAgICAgICAgPFRhYmxlSGVhZGVyVGQga2V5PXtpbmRleH0+e2l0ZW19PC9UYWJsZUhlYWRlclRkPlxyXG4gICAgICAgICAgKSl9XHJcbiAgICAgICAgPC9UYWJsZUhlYWRlclRyPlxyXG4gICAgICA8L3RoZWFkPlxyXG4gICAgICA8dGJvZHk+e2NoaWxkcmVufTwvdGJvZHk+XHJcbiAgICA8L1Jvb3Q+XHJcbiAgKTtcclxufVxyXG5cclxuZXhwb3J0IGRlZmF1bHQgVGFibGU7XHJcbiJdfQ== */"
});

function Table(_ref) {
  var columns = _ref.columns,
      children = _ref.children,
      other = _objectWithoutProperties(_ref, ["columns", "children"]);

  return _react["default"].createElement(Root, other, _react["default"].createElement("thead", null, _react["default"].createElement(TableHeaderTr, null, columns.map(function (item, index) {
    return _react["default"].createElement(TableHeaderTd, {
      key: index
    }, item);
  }))), _react["default"].createElement("tbody", null, children));
}

var _default = Table;
exports["default"] = _default;