import styled from "@emotion/styled";
import React from "react";

import { Box } from "@ewe-it/ewe-design-react";

const Wrapper = styled(Box)`
  padding-top: 0px;
`;

const ValidationBox = styled(Box)`
  padding-bottom: 0px;
`;

const SuccessMessage = styled.div`
  color: green;
  font-size: 13px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 13px;
`;

export default function({ result }) {
  return (
    <Wrapper>
      {result.map((item, index) => {
        if (item.valid) {
          return (
            <ValidationBox>
              <SuccessMessage key={index}>✓ {item.message}</SuccessMessage>
            </ValidationBox>
          );
        } else {
          return (
            <ValidationBox>
              <ErrorMessage key={index}>✖ {item.message}</ErrorMessage>
            </ValidationBox>
          );
        }
      })}
    </Wrapper>
  );
}
