"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var TableRowTr = (0, _styledBase["default"])("div", {
  target: "ej0i80m0",
  label: "TableRowTr"
})(process.env.NODE_ENV === "production" ? {
  name: "kh1uci",
  styles: "display:table-row;margin-bottom:0;border-left:0px solid #e6e6e6;border-right:0px solid #e6e6e6;border-bottom:0px solid #e6e6e6;flex-direction:column;border-radius:0px;"
} : {
  name: "kh1uci",
  styles: "display:table-row;margin-bottom:0;border-left:0px solid #e6e6e6;border-right:0px solid #e6e6e6;border-bottom:0px solid #e6e6e6;flex-direction:column;border-radius:0px;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2Rpdi10YWJsZS1yb3cuanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRzZCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL2Rpdi10YWJsZS1yb3cuanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcclxuaW1wb3J0IFJlYWN0IGZyb20gXCJyZWFjdFwiO1xyXG5cclxuY29uc3QgVGFibGVSb3dUciA9IHN0eWxlZC5kaXZgXHJcbiAgZGlzcGxheTogdGFibGUtcm93O1xyXG4gIG1hcmdpbi1ib3R0b206IDA7XHJcblxyXG4gIGJvcmRlci1sZWZ0OiAwcHggc29saWQgI2U2ZTZlNjtcclxuICBib3JkZXItcmlnaHQ6IDBweCBzb2xpZCAjZTZlNmU2O1xyXG4gIGJvcmRlci1ib3R0b206IDBweCBzb2xpZCAjZTZlNmU2O1xyXG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XHJcbiAgYm9yZGVyLXJhZGl1czogMHB4O1xyXG5gO1xyXG5cclxuZnVuY3Rpb24gRGl2VGFibGVSb3coeyBjaGlsZHJlbiwgLi4ub3RoZXIgfSkge1xyXG4gIHJldHVybiA8VGFibGVSb3dUciB7Li4ub3RoZXJ9PntjaGlsZHJlbn08L1RhYmxlUm93VHI+O1xyXG59XHJcblxyXG5leHBvcnQgZGVmYXVsdCBEaXZUYWJsZVJvdztcclxuIl19 */"
});

function DivTableRow(_ref) {
  var children = _ref.children,
      other = _objectWithoutProperties(_ref, ["children"]);

  return _react["default"].createElement(TableRowTr, other, children);
}

var _default = DivTableRow;
exports["default"] = _default;