"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var DivSpacer = (0, _styledBase["default"])("div", {
  target: "e1v2dazo0",
  label: "DivSpacer"
})(process.env.NODE_ENV === "production" ? {
  name: "yikrym",
  styles: "width:20px;"
} : {
  name: "yikrym",
  styles: "width:20px;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2Rpdi1zcGFjZXIuanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRTRCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL2Rpdi1zcGFjZXIuanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcclxuXHJcbmNvbnN0IERpdlNwYWNlciA9IHN0eWxlZC5kaXZgXHJcbiAgd2lkdGg6IDIwcHg7XHJcbmA7XHJcblxyXG5leHBvcnQgZGVmYXVsdCBEaXZTcGFjZXI7XHJcbiJdfQ== */"
});
var _default = DivSpacer;
exports["default"] = _default;