"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var DialogBoxBody = (0, _styledBase["default"])("div", {
  target: "eh9iagq0",
  label: "DialogBoxBody"
})(process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2RpYWxvZy1ib3gtYm9keS5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFZ0MiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvZGlhbG9nLWJveC1ib2R5LmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHN0eWxlZCBmcm9tIFwiQGVtb3Rpb24vc3R5bGVkXCI7XHJcblxyXG5jb25zdCBEaWFsb2dCb3hCb2R5ID0gc3R5bGVkLmRpdmBgO1xyXG5cclxuZXhwb3J0IGRlZmF1bHQgRGlhbG9nQm94Qm9keTtcclxuIl19 */");
var _default = DialogBoxBody;
exports["default"] = _default;