"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var YoutubeIcon = function YoutubeIcon() {
  return _react["default"].createElement("svg", {
    viewBox: "0 0 24 17",
    width: "24px",
    height: "17px"
  }, _react["default"].createElement("path", {
    d: "M9.522 11.635V4.844l6.484 3.407-6.484 3.384zM23.76 3.667s-.235-1.665-.954-2.399C21.893.305 20.87.301 20.401.245 17.043 0 12.005 0 12.005 0h-.01S6.957 0 3.599.245c-.47.056-1.492.06-2.406 1.023-.719.734-.953 2.4-.953 2.4S0 5.622 0 7.577v1.835c0 1.956.24 3.912.24 3.912s.234 1.665.953 2.399c.914.963 2.113.932 2.647 1.033C5.76 16.943 12 17 12 17s5.043-.008 8.401-.252c.47-.057 1.492-.061 2.405-1.024.72-.734.954-2.4.954-2.4S24 11.37 24 9.414V7.579c0-1.956-.24-3.912-.24-3.912z",
    fillRule: "nonzero",
    fill: "#4F4F4C"
  }));
};

var _default = YoutubeIcon;
exports["default"] = _default;