"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var NavButton = (0, _styledBase["default"])("a", {
  target: "e19v8yrh0",
  label: "NavButton"
})(process.env.NODE_ENV === "production" ? {
  name: "1qnz445",
  styles: "max-width:180px;width:100%;height:42px;background-color:transparent;margin:0;padding:0;border:0;font-size:100%;vertical-align:baseline;color:#50bcb7;border-radius:2px;border:1px solid #e1e1e1;display:flex;align-items:center;justify-content:center;background:#fff;color:#5f5e5a;transition:all 0.3s;&:hover{background-color:#f9f9f9;}cursor:pointer;"
} : {
  name: "1qnz445",
  styles: "max-width:180px;width:100%;height:42px;background-color:transparent;margin:0;padding:0;border:0;font-size:100%;vertical-align:baseline;color:#50bcb7;border-radius:2px;border:1px solid #e1e1e1;display:flex;align-items:center;justify-content:center;background:#fff;color:#5f5e5a;transition:all 0.3s;&:hover{background-color:#f9f9f9;}cursor:pointer;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL25hdi1idXR0b24uanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRTBCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL25hdi1idXR0b24uanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcclxuXHJcbmNvbnN0IE5hdkJ1dHRvbiA9IHN0eWxlZC5hYFxyXG4gIG1heC13aWR0aDogMTgwcHg7XHJcbiAgd2lkdGg6IDEwMCU7XHJcbiAgaGVpZ2h0OiA0MnB4O1xyXG4gIGJhY2tncm91bmQtY29sb3I6IHRyYW5zcGFyZW50O1xyXG4gIG1hcmdpbjogMDtcclxuICBwYWRkaW5nOiAwO1xyXG4gIGJvcmRlcjogMDtcclxuICBmb250LXNpemU6IDEwMCU7XHJcbiAgdmVydGljYWwtYWxpZ246IGJhc2VsaW5lO1xyXG4gIGNvbG9yOiAjNTBiY2I3O1xyXG4gIGJvcmRlci1yYWRpdXM6IDJweDtcclxuICBib3JkZXI6IDFweCBzb2xpZCAjZTFlMWUxO1xyXG4gIGRpc3BsYXk6IGZsZXg7XHJcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcclxuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcclxuICBiYWNrZ3JvdW5kOiAjZmZmO1xyXG4gIGNvbG9yOiAjNWY1ZTVhO1xyXG4gIHRyYW5zaXRpb246IGFsbCAwLjNzO1xyXG4gICY6aG92ZXIge1xyXG4gICAgYmFja2dyb3VuZC1jb2xvcjogI2Y5ZjlmOTtcclxuICB9XHJcbiAgY3Vyc29yOiBwb2ludGVyO1xyXG5gO1xyXG5cclxuZXhwb3J0IGRlZmF1bHQgTmF2QnV0dG9uO1xyXG4iXX0= */"
});
var _default = NavButton;
exports["default"] = _default;