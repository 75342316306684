"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var TableCell = (0, _styledBase["default"])("div", {
  target: "ehcje0",
  label: "TableCell"
})(process.env.NODE_ENV === "production" ? {
  name: "1x5t0v4",
  styles: "display:table-cell;border:0px solid #dbdbdb;padding:3px 2px;font-size:13px;color:#333333;position:relative;padding:12px 8px;border-top:1px solid #c0c0c0;flex-direction:row;justify-content:space-between;vertical-align:middle;"
} : {
  name: "1x5t0v4",
  styles: "display:table-cell;border:0px solid #dbdbdb;padding:3px 2px;font-size:13px;color:#333333;position:relative;padding:12px 8px;border-top:1px solid #c0c0c0;flex-direction:row;justify-content:space-between;vertical-align:middle;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2Rpdi10YWJsZS1jb2x1bW4uanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRzRCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL2Rpdi10YWJsZS1jb2x1bW4uanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcclxuaW1wb3J0IFJlYWN0IGZyb20gXCJyZWFjdFwiO1xyXG5cclxuY29uc3QgVGFibGVDZWxsID0gc3R5bGVkLmRpdmBcclxuICBkaXNwbGF5OiB0YWJsZS1jZWxsO1xyXG4gIGJvcmRlcjogMHB4IHNvbGlkICNkYmRiZGI7XHJcbiAgcGFkZGluZzogM3B4IDJweDtcclxuICBmb250LXNpemU6IDEzcHg7XHJcbiAgY29sb3I6ICMzMzMzMzM7XHJcblxyXG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcclxuICBwYWRkaW5nOiAxMnB4IDhweDtcclxuICBib3JkZXItdG9wOiAxcHggc29saWQgI2MwYzBjMDtcclxuICBmbGV4LWRpcmVjdGlvbjogcm93O1xyXG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcclxuICB2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO1xyXG5gO1xyXG5cclxuZnVuY3Rpb24gRGl2VGFibGVDb2x1bW4oeyBjaGlsZHJlbiwgLi4ub3RoZXIgfSkge1xyXG4gIHJldHVybiA8VGFibGVDZWxsIHsuLi5vdGhlcn0+e2NoaWxkcmVufTwvVGFibGVDZWxsPjtcclxufVxyXG5cclxuZXhwb3J0IGRlZmF1bHQgRGl2VGFibGVDb2x1bW47XHJcbiJdfQ== */"
});

function DivTableColumn(_ref) {
  var children = _ref.children,
      other = _objectWithoutProperties(_ref, ["children"]);

  return _react["default"].createElement(TableCell, other, children);
}

var _default = DivTableColumn;
exports["default"] = _default;