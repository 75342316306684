import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import styled from "@emotion/styled";

import {
  Subline,
  ErrorContainer,
  ActionButton,
  DivSpacer,
  DialogBox,
  DialogBoxBody,
  DialogBoxTextInput,
  GridContainer,
  Box,
  Flex,
} from "@ewe-it/ewe-design-react";

import SubHeadline from "../components/sub-headline";
import ValidationResult from "../components/validation-result";
import passwordValidator from "../utils/password-validator";

import { securityCenter as sec } from "../data-test-attributes";

const Wrapper = styled(Box)`
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }
`;

const InputContainer = styled(Box)`
  padding-bottom: 16px;
`;

const ActionButtonContainer = styled(Flex)`
  justify-content: space-between;
  padding-top: 20px;
`;

const BackButtonContainer = styled(Flex)`
  justify-content: flex-end;
  padding-top: 20px;
`;

const SublineBox = styled(Box)`
  padding: 0 0 20px 0;
`;

const SubHeadlineBox = styled(Box)`
  padding: 0 0 20px 0;
`;

const ErrorBox = styled(Box)`
  padding: 0 0 20px 0;
`;

export const ChangePassword = ({ redirectUri }) => {
  const [returnToCaller, setReturnToCaller] = useState(false);
  const [validationResult, setValidationResult] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [changeState, setChangeState] = useState("input");
  const [input, setInput] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    const newValues = { ...input, [name]: value };
    let validationResults = [];
    if (newValues.newPassword1 !== "" || newValues.newPassword2 !== "") {
      validationResults = passwordValidator(newValues);
    }
    setValidationResult(validationResults);
    setInput(newValues);
  };

  const changePassword = async e => {
    const validationResults = passwordValidator(input);
    setValidationResult(validationResults);
    if (validationResults.findIndex(item => !item.valid) > -1) {
      return;
    }
    if (input.oldPassword === "") {
      setErrorMessage(
        "Passwort falsch: Bitte geben Sie Ihr aktuelles Passwort ein!",
      );
      return;
    }
    setErrorMessage("");
    setChangeState("changing");
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, input.oldPassword, input.newPassword1);
      setChangeState("changed");
    } catch (e) {
      if (e.code === "NotAuthorizedException") {
        setErrorMessage(
          "Passwort falsch: Bitte geben Sie Ihr aktuelles Passwort ein!",
        );
      } else if (e.code === "LimitExceededException") {
        setErrorMessage(
          "Zu viele Versuche - Bitte versuchen Sie es später erneut!",
        );
      } else {
        setErrorMessage(
          "Es ist ein Fehler aufgetreten - Bitte versuchen Sie es erneut!",
        );
      }
      setChangeState("failed");
    }
  };

  const redirectToCaller = e => {
    setReturnToCaller(true);
  };

  if (returnToCaller) {
    if (redirectUri) {
      window.location.assign(redirectUri);
      return null;
    }
    return <Redirect to="/" />;
  }

  if (changeState === "changed") {
    return (
      <Wrapper>
        <GridContainer>
          <DialogBox>
            <SubHeadlineBox>
              <SubHeadline label="Passwort ändern"></SubHeadline>
            </SubHeadlineBox>

            <SublineBox>
              <Subline>Ihr Passwort wurde erfolgreich geändert!</Subline>
            </SublineBox>
            <BackButtonContainer>
              <ActionButton
                data-test={sec.changePassword.ok}
                variant="primary"
                onClick={redirectToCaller}
              >
                Fertig
              </ActionButton>
            </BackButtonContainer>
          </DialogBox>
        </GridContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <GridContainer>
        <DialogBox>
          <SubHeadlineBox>
            <SubHeadline label="Passwort ändern"></SubHeadline>
          </SubHeadlineBox>

          <SublineBox>
            <Subline>
              Bitte geben Sie ihr altes, sowie zweimal ihr neues Passwort ein
            </Subline>
          </SublineBox>

          <DialogBoxBody>
            {errorMessage && (
              <ErrorBox>
                <ErrorContainer>{errorMessage}</ErrorContainer>
              </ErrorBox>
            )}
            <InputContainer>
              <DialogBoxTextInput
                data-test={sec.changePassword.oldPassword}
                onChange={handleInputChange}
                value={input.oldPassword}
                name="oldPassword"
                label="Altes Passwort"
                placeholder="Bitte geben Sie Ihr altes Passwort ein"
                type="password"
              />
            </InputContainer>
            <InputContainer>
              <DialogBoxTextInput
                data-test={sec.changePassword.newPassword1}
                onChange={handleInputChange}
                value={input.newPassword1}
                name="newPassword1"
                label="Neues Passwort"
                placeholder="Bitte geben Sie Ihr neues Passwort ein"
                type="password"
              />
            </InputContainer>
            <InputContainer>
              <DialogBoxTextInput
                data-test={sec.changePassword.newPassword2}
                onChange={handleInputChange}
                value={input.newPassword2}
                name="newPassword2"
                label="Neues Passwort (Wiederholung)"
                placeholder="Bitte wiederholen Sie Ihr neues Passwort"
                type="password"
              />
            </InputContainer>
            <ValidationResult result={validationResult} />
            <ActionButtonContainer>
              <ActionButton
                showIcon
                data-test={sec.changePassword.cancel}
                onClick={redirectToCaller}
                variant="white"
              >
                Zurück
              </ActionButton>
              <DivSpacer />
              <ActionButton
                showIcon
                data-test={sec.changePassword.changePassword}
                onClick={changePassword}
              >
                Passwort ändern
              </ActionButton>
            </ActionButtonContainer>
          </DialogBoxBody>
        </DialogBox>
      </GridContainer>
    </Wrapper>
  );
};
