"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var MainContainer = (0, _styledBase["default"])("div", {
  target: "e1o4587z0",
  label: "MainContainer"
})(process.env.NODE_ENV === "production" ? {
  name: "15gny5c",
  styles: "margin-left:auto;margin-right:auto;max-width:1020px;padding:40px 20px 0 20px;box-sizing:border-box;@media (min-width:768px){margin-top:30px;padding-top:80px;}"
} : {
  name: "15gny5c",
  styles: "margin-left:auto;margin-right:auto;max-width:1020px;padding:40px 20px 0 20px;box-sizing:border-box;@media (min-width:768px){margin-top:30px;padding-top:80px;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL21haW4tY29udGFpbmVyLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVnQyIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9tYWluLWNvbnRhaW5lci5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xyXG5cclxuY29uc3QgTWFpbkNvbnRhaW5lciA9IHN0eWxlZC5kaXZgXHJcbiAgbWFyZ2luLWxlZnQ6IGF1dG87XHJcbiAgbWFyZ2luLXJpZ2h0OiBhdXRvO1xyXG4gIG1heC13aWR0aDogMTAyMHB4O1xyXG4gIHBhZGRpbmc6IDQwcHggMjBweCAwIDIwcHg7XHJcbiAgYm94LXNpemluZzogYm9yZGVyLWJveDtcclxuXHJcbiAgQG1lZGlhIChtaW4td2lkdGg6IDc2OHB4KSB7XHJcbiAgICBtYXJnaW4tdG9wOiAzMHB4O1xyXG4gICAgcGFkZGluZy10b3A6IDgwcHg7XHJcbiAgfVxyXG5gO1xyXG5cclxuZXhwb3J0IGRlZmF1bHQgTWFpbkNvbnRhaW5lcjtcclxuIl19 */"
});
var _default = MainContainer;
exports["default"] = _default;