const changePassword = {
  oldPassword: "change-password-old-password-field",
  newPassword1: "change-password-new-password-field1",
  newPassword2: "change-password-new-password-field2",
  cancel: "change-password-cancel",
  changePassword: "change-password-change",
  ok: "change-password-ok",
};

const main = {
  eweLogo: "ewe-logo",
  changePassword: "changePassword",
  logout: "logout",
};

export const securityCenter = {
  changePassword,
  main,
};
