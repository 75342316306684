"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var ErrorContainer = (0, _styledBase["default"])("div", {
  target: "e1u8pwlc0",
  label: "ErrorContainer"
})(process.env.NODE_ENV === "production" ? {
  name: "jiwdn8",
  styles: "font-size:13px;color:red;font-weight:700;"
} : {
  name: "jiwdn8",
  styles: "font-size:13px;color:red;font-weight:700;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2Vycm9yLWNvbnRhaW5lci5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFaUMiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvZXJyb3ItY29udGFpbmVyLmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHN0eWxlZCBmcm9tIFwiQGVtb3Rpb24vc3R5bGVkXCI7XHJcblxyXG5jb25zdCBFcnJvckNvbnRhaW5lciA9IHN0eWxlZC5kaXZgXHJcbiAgZm9udC1zaXplOiAxM3B4O1xyXG4gIGNvbG9yOiByZWQ7XHJcbiAgZm9udC13ZWlnaHQ6IDcwMDtcclxuYDtcclxuXHJcbmV4cG9ydCBkZWZhdWx0IEVycm9yQ29udGFpbmVyO1xyXG4iXX0= */"
});
var _default = ErrorContainer;
exports["default"] = _default;