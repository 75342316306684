"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var TableRowTr = (0, _styledBase["default"])("tr", {
  target: "ep0lhnn0",
  label: "TableRowTr"
})(process.env.NODE_ENV === "production" ? {
  name: "1pv0f68",
  styles: "padding:8px;background-color:#ffffff;font-size:14px;font-weight:600;line-height:1.71;color:#4d4d4d;"
} : {
  name: "1pv0f68",
  styles: "padding:8px;background-color:#ffffff;font-size:14px;font-weight:600;line-height:1.71;color:#4d4d4d;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL3RhYmxlLXJvdy5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHNEIiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvdGFibGUtcm93LmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHN0eWxlZCBmcm9tIFwiQGVtb3Rpb24vc3R5bGVkXCI7XHJcbmltcG9ydCBSZWFjdCBmcm9tIFwicmVhY3RcIjtcclxuXHJcbmNvbnN0IFRhYmxlUm93VHIgPSBzdHlsZWQudHJgXHJcbiAgcGFkZGluZzogOHB4O1xyXG4gIGJhY2tncm91bmQtY29sb3I6ICNmZmZmZmY7XHJcbiAgZm9udC1zaXplOiAxNHB4O1xyXG4gIGZvbnQtd2VpZ2h0OiA2MDA7XHJcbiAgbGluZS1oZWlnaHQ6IDEuNzE7XHJcbiAgY29sb3I6ICM0ZDRkNGQ7XHJcbmA7XHJcblxyXG5mdW5jdGlvbiBUYWJsZVJvdyh7IGNoaWxkcmVuLCAuLi5vdGhlciB9KSB7XHJcbiAgcmV0dXJuIDxUYWJsZVJvd1RyIHsuLi5vdGhlcn0+e2NoaWxkcmVufTwvVGFibGVSb3dUcj47XHJcbn1cclxuXHJcbmV4cG9ydCBkZWZhdWx0IFRhYmxlUm93O1xyXG4iXX0= */"
});

function TableRow(_ref) {
  var children = _ref.children,
      other = _objectWithoutProperties(_ref, ["children"]);

  return _react["default"].createElement(TableRowTr, other, children);
}

var _default = TableRow;
exports["default"] = _default;