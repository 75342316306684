"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Subline = (0, _styledBase["default"])("span", {
  target: "eug8nms0",
  label: "Subline"
})(process.env.NODE_ENV === "production" ? {
  name: "hogqdq",
  styles: "font-size:13px;line-height:1.69;color:#333333;"
} : {
  name: "hogqdq",
  styles: "font-size:13px;line-height:1.69;color:#333333;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL3N1YmxpbmUuanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRTJCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL3N1YmxpbmUuanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcclxuXHJcbmNvbnN0IFN1YmxpbmUgPSBzdHlsZWQuc3BhbmBcclxuICBmb250LXNpemU6IDEzcHg7XHJcbiAgbGluZS1oZWlnaHQ6IDEuNjk7XHJcbiAgY29sb3I6ICMzMzMzMzM7XHJcbmA7XHJcblxyXG5leHBvcnQgZGVmYXVsdCBTdWJsaW5lO1xyXG4iXX0= */"
});
var _default = Subline;
exports["default"] = _default;