"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function ArrowDown(_ref) {
  var title = _ref.title,
      rest = _objectWithoutProperties(_ref, ["title"]);

  return _react["default"].createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 16 16",
    "aria-labelledby": "title"
  }, rest), _react["default"].createElement("title", null, "arrow_down_grey"), _react["default"].createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("g", {
    fill: "#ffffff",
    fillRule: "nonzero"
  }, _react["default"].createElement("path", {
    d: "M4.24329415,4.83103527 C3.87383513,4.42052525 3.24154529,4.38724683 2.83103527,4.75670585 C2.42052525,5.12616487 2.38724683,5.75845471 2.75670585,6.16896473 L7.25670585,11.1689647 C7.65394818,11.6103451 8.34605182,11.6103451 8.74329415,11.1689647 L13.2432941,6.16896473 C13.6127532,5.75845471 13.5794748,5.12616487 13.1689647,4.75670585 C12.7584547,4.38724683 12.1261649,4.42052525 11.7567059,4.83103527 L8,9.00515288 L4.24329415,4.83103527 Z"
  }))));
}

var _default = ArrowDown;
exports["default"] = _default;