"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var HeadlineContent = (0, _styledBase["default"])("div", {
  target: "e1otpgql0",
  label: "HeadlineContent"
})(process.env.NODE_ENV === "production" ? {
  name: "c40g5n",
  styles: "@media (min-width:768px){font-size:28px;line-height:1.14;}font-size:20px;line-height:1.6;box-sizing:border-box;margin:0;padding:0;border:0;font:inherit;vertical-align:baseline;display:block;"
} : {
  name: "c40g5n",
  styles: "@media (min-width:768px){font-size:28px;line-height:1.14;}font-size:20px;line-height:1.6;box-sizing:border-box;margin:0;padding:0;border:0;font:inherit;vertical-align:baseline;display:block;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2hlYWRsaW5lLWNvbnRlbnQuanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRWtDIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL2hlYWRsaW5lLWNvbnRlbnQuanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcclxuXHJcbmNvbnN0IEhlYWRsaW5lQ29udGVudCA9IHN0eWxlZC5kaXZgXHJcbiAgQG1lZGlhIChtaW4td2lkdGg6IDc2OHB4KSB7XHJcbiAgICBmb250LXNpemU6IDI4cHg7XHJcbiAgICBsaW5lLWhlaWdodDogMS4xNDtcclxuICB9XHJcbiAgZm9udC1zaXplOiAyMHB4O1xyXG4gIGxpbmUtaGVpZ2h0OiAxLjY7XHJcbiAgYm94LXNpemluZzogYm9yZGVyLWJveDtcclxuICBtYXJnaW46IDA7XHJcbiAgcGFkZGluZzogMDtcclxuICBib3JkZXI6IDA7XHJcbiAgZm9udDogaW5oZXJpdDtcclxuICB2ZXJ0aWNhbC1hbGlnbjogYmFzZWxpbmU7XHJcbiAgZGlzcGxheTogYmxvY2s7XHJcbmA7XHJcblxyXG5leHBvcnQgZGVmYXVsdCBIZWFkbGluZUNvbnRlbnQ7XHJcbiJdfQ== */"
});
var _default = HeadlineContent;
exports["default"] = _default;