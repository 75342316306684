"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = DivCenterGray;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

var _react = _interopRequireDefault(require("react"));

var _container = _interopRequireDefault(require("./container"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var DivGrey = (0, _styledBase["default"])("div", {
  target: "eyiffrq0",
  label: "DivGrey"
})(process.env.NODE_ENV === "production" ? {
  name: "1emgluz",
  styles: "@media (min-width:768px){padding:20px 0px 20px 0px;}padding:0px 0px 10px 0px;max-width:1024px;background-color:#ebebeb;display:flex;justify-content:space-between;flex-wrap:wrap;"
} : {
  name: "1emgluz",
  styles: "@media (min-width:768px){padding:20px 0px 20px 0px;}padding:0px 0px 10px 0px;max-width:1024px;background-color:#ebebeb;display:flex;justify-content:space-between;flex-wrap:wrap;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2Rpdi1jZW50ZXItZ3JheS5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJMEIiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvZGl2LWNlbnRlci1ncmF5LmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHN0eWxlZCBmcm9tIFwiQGVtb3Rpb24vc3R5bGVkXCI7XHJcbmltcG9ydCBSZWFjdCBmcm9tIFwicmVhY3RcIjtcclxuaW1wb3J0IENvbnRhaW5lciBmcm9tIFwiLi9jb250YWluZXJcIjtcclxuXHJcbmNvbnN0IERpdkdyZXkgPSBzdHlsZWQuZGl2YFxyXG4gIEBtZWRpYSAobWluLXdpZHRoOiA3NjhweCkge1xyXG4gICAgcGFkZGluZzogMjBweCAwcHggMjBweCAwcHg7XHJcbiAgfVxyXG4gIHBhZGRpbmc6IDBweCAwcHggMTBweCAwcHg7XHJcbiAgbWF4LXdpZHRoOiAxMDI0cHg7XHJcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ViZWJlYjtcclxuICBkaXNwbGF5OiBmbGV4O1xyXG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcclxuICBmbGV4LXdyYXA6IHdyYXA7XHJcbmA7XHJcblxyXG5jb25zdCBEaXZDZW50ZXIgPSBzdHlsZWQuZGl2YFxyXG4gIHRleHQtYWxpZ246IGxlZnQ7XHJcbiAgQG1lZGlhIChtaW4td2lkdGg6IDc2OHB4KSB7XHJcbiAgICB3aWR0aDogNzAlO1xyXG4gIH1cclxuICB3aWR0aDogODAlO1xyXG4gIG1hcmdpbjogMCBhdXRvO1xyXG5gO1xyXG5cclxuZXhwb3J0IGRlZmF1bHQgZnVuY3Rpb24gRGl2Q2VudGVyR3JheSh7IGNoaWxkcmVuLCAuLi5vdGhlciB9KSB7XHJcbiAgcmV0dXJuIChcclxuICAgIDxDb250YWluZXI+XHJcbiAgICAgIDxEaXZHcmV5PlxyXG4gICAgICAgIDxEaXZDZW50ZXIgey4uLm90aGVyfT57Y2hpbGRyZW59PC9EaXZDZW50ZXI+XHJcbiAgICAgIDwvRGl2R3JleT5cclxuICAgIDwvQ29udGFpbmVyPlxyXG4gICk7XHJcbn1cclxuIl19 */"
});
var DivCenter = (0, _styledBase["default"])("div", {
  target: "eyiffrq1",
  label: "DivCenter"
})(process.env.NODE_ENV === "production" ? {
  name: "rp35bz",
  styles: "text-align:left;@media (min-width:768px){width:70%;}width:80%;margin:0 auto;"
} : {
  name: "rp35bz",
  styles: "text-align:left;@media (min-width:768px){width:70%;}width:80%;margin:0 auto;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2Rpdi1jZW50ZXItZ3JheS5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFnQjRCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL2Rpdi1jZW50ZXItZ3JheS5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xyXG5pbXBvcnQgUmVhY3QgZnJvbSBcInJlYWN0XCI7XHJcbmltcG9ydCBDb250YWluZXIgZnJvbSBcIi4vY29udGFpbmVyXCI7XHJcblxyXG5jb25zdCBEaXZHcmV5ID0gc3R5bGVkLmRpdmBcclxuICBAbWVkaWEgKG1pbi13aWR0aDogNzY4cHgpIHtcclxuICAgIHBhZGRpbmc6IDIwcHggMHB4IDIwcHggMHB4O1xyXG4gIH1cclxuICBwYWRkaW5nOiAwcHggMHB4IDEwcHggMHB4O1xyXG4gIG1heC13aWR0aDogMTAyNHB4O1xyXG4gIGJhY2tncm91bmQtY29sb3I6ICNlYmViZWI7XHJcbiAgZGlzcGxheTogZmxleDtcclxuICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XHJcbiAgZmxleC13cmFwOiB3cmFwO1xyXG5gO1xyXG5cclxuY29uc3QgRGl2Q2VudGVyID0gc3R5bGVkLmRpdmBcclxuICB0ZXh0LWFsaWduOiBsZWZ0O1xyXG4gIEBtZWRpYSAobWluLXdpZHRoOiA3NjhweCkge1xyXG4gICAgd2lkdGg6IDcwJTtcclxuICB9XHJcbiAgd2lkdGg6IDgwJTtcclxuICBtYXJnaW46IDAgYXV0bztcclxuYDtcclxuXHJcbmV4cG9ydCBkZWZhdWx0IGZ1bmN0aW9uIERpdkNlbnRlckdyYXkoeyBjaGlsZHJlbiwgLi4ub3RoZXIgfSkge1xyXG4gIHJldHVybiAoXHJcbiAgICA8Q29udGFpbmVyPlxyXG4gICAgICA8RGl2R3JleT5cclxuICAgICAgICA8RGl2Q2VudGVyIHsuLi5vdGhlcn0+e2NoaWxkcmVufTwvRGl2Q2VudGVyPlxyXG4gICAgICA8L0RpdkdyZXk+XHJcbiAgICA8L0NvbnRhaW5lcj5cclxuICApO1xyXG59XHJcbiJdfQ== */"
});

function DivCenterGray(_ref) {
  var children = _ref.children,
      other = _objectWithoutProperties(_ref, ["children"]);

  return _react["default"].createElement(_container["default"], null, _react["default"].createElement(DivGrey, null, _react["default"].createElement(DivCenter, other, children)));
}