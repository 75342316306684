"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function UserCircle(_ref) {
  var title = _ref.title,
      rest = _objectWithoutProperties(_ref, ["title"]);

  return _react["default"].createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 18 18",
    "aria-labelledby": "title"
  }, rest), _react["default"].createElement("title", null, "user-cricle-tke"), _react["default"].createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("g", {
    transform: "translate(-85.000000, -15.000000)"
  }, _react["default"].createElement("g", {
    transform: "translate(85.000000, 15.000000)"
  }, _react["default"].createElement("path", {
    d: "M9,17 C13.418278,17 17,13.418278 17,9 C17,4.581722 13.418278,1 9,1 C4.581722,1 1,4.581722 1,9 C1,13.418278 4.581722,17 9,17 Z M9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 Z",
    fill: "#ffffff",
    fillRule: "nonzero"
  }), _react["default"].createElement("path", {
    d: "M15.0801395,14.9026723 C14.2371011,14.2377231 12.5414018,13.6860239 10.5498047,12.939175 L10.5498047,11.064175 C10.5498047,11.064175 11.4873047,10.439175 11.4873047,9.18917502 C11.7998047,9.18917502 12.1123047,7.93917502 11.4873047,7.62667502 C11.4873047,7.44042502 12.3241797,5.87542502 12.1123047,4.81417502 C11.7998047,3.25167502 7.42480469,3.25167502 7.11230469,4.50167502 C5.23730469,4.50167502 6.48730469,7.37292502 6.48730469,7.62667502 C5.86230469,7.93917502 6.17480469,9.18917502 6.48730469,9.18917502 C6.48730469,10.439175 7.42480469,11.064175 7.42480469,11.064175 L7.42480469,12.939175 C5.40607737,13.6961978 3.69095693,14.2527177 2.86025815,14.9299107",
    stroke: "#ffffff",
    strokeLinejoin: "round"
  })))));
}

var _default = UserCircle;
exports["default"] = _default;