import React, { useEffect, useState } from "react";
import { ThemeProvider } from "emotion-theming";
import styled from "@emotion/styled";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import {
  theme,
  GlobalStyle,
  TopNav,
  SubNav,
  Footer,
} from "@ewe-it/ewe-design-react";
import { getCookieValue } from "@ewe-it/ewe-frontend-utils";
import { ChangePassword } from "./pages/change-password";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faKey } from "@fortawesome/free-solid-svg-icons";

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  padding-bottom: 60px;
  @media (min-width: 768px) {
    padding-bottom: 800px;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

Amplify.configure({
  Auth: {
    region: "eu-central-1", //! Konfiguration
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
});

const oauth = {
  //domain: 'eweauth.auth.eu-central-1.amazoncognito.com',
  domain: process.env.REACT_APP_IDP_DOMAIN, //! Rename Config
  scope: ["email", "openid"],
  redirectSignIn: window.location.href,
  redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
  responseType: "token",
};

Auth.configure({ oauth });
library.add(faKey);

function useAuth() {
  const [state, setState] = useState({
    authState: "loading",
    authData: null,
    authError: null,
  });

  useEffect(() => {
    async function checkAuth() {
      try {
        const user = await Auth.currentAuthenticatedUser();

        setState({
          authState: "signedIn",
          authData: user,
        });
        setInterval(() => {
          const loginCookie = getCookieValue("loginsession");
          if (!loginCookie || loginCookie === "" || loginCookie === "logout") {
            Auth.signOut();
          }
        }, 1000);
      } catch (error) {
        Auth.federatedSignIn();
      }
    }
    checkAuth();
  }, []);

  return state;
}

function App() {
  const { authState, authData } = useAuth();

  function handleLogoutLink() {
    Auth.signOut();
    Auth.signOut({ global: true });
  }

  if (authState !== "signedIn") {
    return null;
  }
  const url = new URL(window.location.href);
  const redirectUri = url.searchParams.get("redirect_uri");
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <PageContainer>
        <ContentWrapper>
          <TopNav hasMobileMenu={false} />
          <SubNav username={authData.username} />
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <ChangePassword redirectUri={redirectUri} />}
              />
              <Route
                path="/changePassword"
                render={() => <ChangePassword redirectUri={redirectUri} />}
              />
              <Route
                exact
                path="/logout"
                render={() => {
                  handleLogoutLink();
                  return null;
                }}
              />
            </Switch>
          </Router>
        </ContentWrapper>
        <FooterContainer>
          <Footer backgroundUrl="/footer-back.svg"></Footer>
        </FooterContainer>
      </PageContainer>
    </ThemeProvider>
  );
}

export default App;
