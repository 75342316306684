"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function Logo(_ref) {
  var title = _ref.title,
      className = _ref.className,
      rest = _objectWithoutProperties(_ref, ["title", "className"]);

  return _react["default"].createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 59 20",
    "aria-labelledby": "title",
    className: className
  }, rest), _react["default"].createElement("title", {
    id: "title"
  }, title), _react["default"].createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("g", {
    fill: "#FFF101"
  }, _react["default"].createElement("polygon", {
    points: "2.986875 -8.19444444e-05 0 19.6663799 12.1226562 19.6663799 12.8447917 14.9310153 6.17779167 14.9310153 6.63155903 11.8070882 12.2717951 11.8070882 12.9044062 7.57650139 7.26539931 7.57650139 7.68761806 4.73569236 14.3830937 4.73569236 15.1080972 -8.19444444e-05"
  }), _react["default"].createElement("path", {
    d: "M17.0306778,0.00149548611 L22.7198757,0.00149548611 L23.5811118,11.2500094 L23.6956292,11.2500094 L28.2468236,0.00149548611 L33.6561812,0.00149548611 L34.0751222,11.4737177 L34.1519451,11.4737177 C34.1519451,11.4737177 37.9652299,6.19198854 38.7791431,6.14583333e-05 L43.6140701,6.14583333e-05 C42.4561951,10.2818358 34.7468618,19.6628358 34.7468618,19.6628358 L29.7406708,19.6628358 L28.9703931,8.4921691 L28.8933653,8.4921691 L24.4460354,19.6628358 L19.2052785,19.6628358 L17.0306778,0.00149548611 Z"
  }), _react["default"].createElement("polygon", {
    points: "45.9461066 0 58.0708115 0 57.347242 4.73556944 50.6517663 4.73556944 50.2297524 7.57637847 55.869374 7.57637847 55.2369677 11.8071701 49.5967316 11.8071701 49.1441934 14.9310972 55.8085302 14.9310972 55.0853705 19.6664618 42.9598462 19.6664618"
  }))));
}

Logo.propTypes = {
  title: _propTypes["default"].string,
  className: _propTypes["default"].string
};
Logo.defaultProps = {
  title: "EWE Logo",
  className: ""
};
var _default = Logo;
exports["default"] = _default;