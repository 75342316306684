"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUrlParameter = getUrlParameter;

function getUrlParameter(name) {
  var url = new URL(window.location.href);
  return url.searchParams.get(name);
}