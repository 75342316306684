"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Headline = (0, _styledBase["default"])("div", {
  target: "e285aum0",
  label: "Headline"
})(process.env.NODE_ENV === "production" ? {
  name: "1pgx52c",
  styles: "@media (min-width:768px){padding-top:30px;padding-bottom:10px;}padding-top:5px;padding-bottom:19px;display:-ms-flexbox;display:flex;align-items:center;justify-content:center;box-sizing:border-box;margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;"
} : {
  name: "1pgx52c",
  styles: "@media (min-width:768px){padding-top:30px;padding-bottom:10px;}padding-top:5px;padding-bottom:19px;display:-ms-flexbox;display:flex;align-items:center;justify-content:center;box-sizing:border-box;margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2hlYWRsaW5lLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUUyQiIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9oZWFkbGluZS5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xyXG5cclxuY29uc3QgSGVhZGxpbmUgPSBzdHlsZWQuZGl2YFxyXG4gIEBtZWRpYSAobWluLXdpZHRoOiA3NjhweCkge1xyXG4gICAgcGFkZGluZy10b3A6IDMwcHg7XHJcbiAgICBwYWRkaW5nLWJvdHRvbTogMTBweDtcclxuICB9XHJcbiAgcGFkZGluZy10b3A6IDVweDtcclxuICBwYWRkaW5nLWJvdHRvbTogMTlweDtcclxuICBkaXNwbGF5OiAtbXMtZmxleGJveDtcclxuICBkaXNwbGF5OiBmbGV4O1xyXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XHJcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XHJcbiAgYm94LXNpemluZzogYm9yZGVyLWJveDtcclxuICBtYXJnaW46IDA7XHJcbiAgcGFkZGluZzogMDtcclxuICBib3JkZXI6IDA7XHJcbiAgZm9udC1zaXplOiAxMDAlO1xyXG4gIGZvbnQ6IGluaGVyaXQ7XHJcbiAgdmVydGljYWwtYWxpZ246IGJhc2VsaW5lO1xyXG5gO1xyXG5cclxuZXhwb3J0IGRlZmF1bHQgSGVhZGxpbmU7XHJcbiJdfQ== */"
});
var _default = Headline;
exports["default"] = _default;