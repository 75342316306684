"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var SubHeadlineContent = (0, _styledBase["default"])("div", {
  target: "e1apufyd0",
  label: "SubHeadlineContent"
})(process.env.NODE_ENV === "production" ? {
  name: "l5gta0",
  styles: "font-size:20px;font-weight:400;font-style:normal;font-stretch:normal;line-height:normal;box-sizing:border-box;margin:0;padding:0;border:0;color:#262626;"
} : {
  name: "l5gta0",
  styles: "font-size:20px;font-weight:400;font-style:normal;font-stretch:normal;line-height:normal;box-sizing:border-box;margin:0;padding:0;border:0;color:#262626;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL3N1Yi1oZWFkbGluZS1jb250ZW50LmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVxQyIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9zdWItaGVhZGxpbmUtY29udGVudC5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiO1xyXG5cclxuY29uc3QgU3ViSGVhZGxpbmVDb250ZW50ID0gc3R5bGVkLmRpdmBcclxuICBmb250LXNpemU6IDIwcHg7XHJcbiAgZm9udC13ZWlnaHQ6IDQwMDtcclxuICBmb250LXN0eWxlOiBub3JtYWw7XHJcbiAgZm9udC1zdHJldGNoOiBub3JtYWw7XHJcbiAgbGluZS1oZWlnaHQ6IG5vcm1hbDtcclxuICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xyXG4gIG1hcmdpbjogMDtcclxuICBwYWRkaW5nOiAwO1xyXG4gIGJvcmRlcjogMDtcclxuICBjb2xvcjogIzI2MjYyNjtcclxuYDtcclxuXHJcbmV4cG9ydCBkZWZhdWx0IFN1YkhlYWRsaW5lQ29udGVudDtcclxuIl19 */"
});
var _default = SubHeadlineContent;
exports["default"] = _default;