"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var ColumnContainer = (0, _styledBase["default"])("div", {
  target: "e7ye1ke0",
  label: "ColumnContainer"
})(process.env.NODE_ENV === "production" ? {
  name: "11wxkfb",
  styles: "display:flex;flex-direction:row;float:center;margin-top:30px;margin-left:auto;margin-right:auto;width:100%;max-width:100%;justify-content:center;align-content:center;"
} : {
  name: "11wxkfb",
  styles: "display:flex;flex-direction:row;float:center;margin-top:30px;margin-left:auto;margin-right:auto;width:100%;max-width:100%;justify-content:center;align-content:center;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2NvbHVtbi1jb250YWluZXIuanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRWtDIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL2NvbHVtbi1jb250YWluZXIuanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcclxuXHJcbmNvbnN0IENvbHVtbkNvbnRhaW5lciA9IHN0eWxlZC5kaXZgXHJcbiAgZGlzcGxheTogZmxleDtcclxuICBmbGV4LWRpcmVjdGlvbjogcm93O1xyXG4gIGZsb2F0OiBjZW50ZXI7XHJcblxyXG4gIG1hcmdpbi10b3A6IDMwcHg7XHJcbiAgbWFyZ2luLWxlZnQ6IGF1dG87XHJcbiAgbWFyZ2luLXJpZ2h0OiBhdXRvO1xyXG5cclxuICB3aWR0aDogMTAwJTtcclxuICBtYXgtd2lkdGg6IDEwMCU7XHJcblxyXG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xyXG4gIGFsaWduLWNvbnRlbnQ6IGNlbnRlcjtcclxuYDtcclxuXHJcbmV4cG9ydCBkZWZhdWx0IENvbHVtbkNvbnRhaW5lcjtcclxuIl19 */"
});
var _default = ColumnContainer;
exports["default"] = _default;