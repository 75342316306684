"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var CrossIcon = function CrossIcon() {
  return _react["default"].createElement("svg", {
    viewBox: "0 0 20 20",
    width: "20px",
    height: "20px"
  }, _react["default"].createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("path", {
    fill: "",
    d: "M0 0h20v20H0z"
  }), _react["default"].createElement("g", {
    fill: "#ffffff",
    transform: "translate(2 2)"
  }, _react["default"].createElement("rect", {
    width: "18",
    height: "2",
    x: "-1",
    y: "7",
    rx: "1",
    transform: "rotate(45 8 8)"
  }), _react["default"].createElement("rect", {
    width: "18",
    height: "2",
    x: "-1",
    y: "7",
    rx: "1",
    transform: "scale(-1 1) rotate(45 0 -11.314)"
  }))));
};

var _default = CrossIcon;
exports["default"] = _default;