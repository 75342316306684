export default input => {
  let validationResults = [];
  validationResults.push({
    message: "Mindestens ein Kleinbuchstabe",
    valid: /[a-z]/.test(input.newPassword1),
  });
  validationResults.push({
    message: "Mindestens ein Großbuchstabe",
    valid: /[A-Z]/.test(input.newPassword1),
  });
  validationResults.push({
    message: "Mindestens ein Sonderzeichen",
    valid: /[$*.[\]{}()?"!@#%&/\\,><':;|_~`^-]/.test(input.newPassword1),
  });
  validationResults.push({
    message: "Mindestens eine Ziffer",
    valid: /[0-9]/.test(input.newPassword1),
  });
  validationResults.push({
    message: "Mindestens 8 Zeichen",
    valid: input.newPassword1.length >= 8,
  });
  validationResults.push({
    message: "Passwort und Passwortwiederholung identisch",
    valid: input.newPassword1 === input.newPassword2,
  });
  return validationResults;
};
