"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var DialogBox = (0, _styledBase["default"])("div", {
  target: "e1pufnt80",
  label: "DialogBox"
})(process.env.NODE_ENV === "production" ? {
  name: "1v9kdux",
  styles: "margin-left:0;margin-right:0;padding:35px 25px;border-radius:0;box-shadow:rgba(0,0,0,0.3) 0px 0px 3px 0px;position:relative;display:inline-block;width:100%;max-width:540px;@media (min-width:600px){padding:35px 40px;border-radius:6px;}"
} : {
  name: "1v9kdux",
  styles: "margin-left:0;margin-right:0;padding:35px 25px;border-radius:0;box-shadow:rgba(0,0,0,0.3) 0px 0px 3px 0px;position:relative;display:inline-block;width:100%;max-width:540px;@media (min-width:600px){padding:35px 40px;border-radius:6px;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL2RpYWxvZy1ib3guanMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRTRCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL2RpYWxvZy1ib3guanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcclxuXHJcbmNvbnN0IERpYWxvZ0JveCA9IHN0eWxlZC5kaXZgXHJcbiAgbWFyZ2luLWxlZnQ6IDA7XHJcbiAgbWFyZ2luLXJpZ2h0OiAwO1xyXG4gIHBhZGRpbmc6IDM1cHggMjVweDtcclxuICBib3JkZXItcmFkaXVzOiAwO1xyXG4gIGJveC1zaGFkb3c6IHJnYmEoMCwgMCwgMCwgMC4zKSAwcHggMHB4IDNweCAwcHg7XHJcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xyXG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcclxuICB3aWR0aDogMTAwJTtcclxuICBtYXgtd2lkdGg6IDU0MHB4O1xyXG5cclxuICBAbWVkaWEgKG1pbi13aWR0aDogNjAwcHgpIHtcclxuICAgIHBhZGRpbmc6IDM1cHggNDBweDtcclxuICAgIGJvcmRlci1yYWRpdXM6IDZweDtcclxuICB9XHJcbmA7XHJcblxyXG5leHBvcnQgZGVmYXVsdCBEaWFsb2dCb3g7XHJcbiJdfQ== */"
});
var _default = DialogBox;
exports["default"] = _default;