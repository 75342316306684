"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function Burger(_ref) {
  var title = _ref.title,
      width = _ref.width,
      height = _ref.height,
      rest = _objectWithoutProperties(_ref, ["title", "width", "height"]);

  return _react["default"].createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 18 18",
    width: width,
    height: height,
    "aria-labelledby": "title"
  }, rest), _react["default"].createElement("title", null, title), _react["default"].createElement("g", {
    fill: "#ffffff",
    fillRule: "evenodd"
  }, _react["default"].createElement("rect", {
    width: "18",
    height: "2",
    rx: "1"
  }), _react["default"].createElement("rect", {
    width: "18",
    height: "2",
    y: "7",
    rx: "1"
  }), _react["default"].createElement("rect", {
    width: "18",
    height: "2",
    y: "14",
    rx: "1"
  })));
}

Burger.propTypes = {
  title: _propTypes["default"].string,
  width: _propTypes["default"].string,
  height: _propTypes["default"].string
};
Burger.defaultProps = {
  title: "header-burger-white",
  width: "18px",
  height: "18px"
};
var _default = Burger;
exports["default"] = _default;