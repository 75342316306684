"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledBase = _interopRequireDefault(require("@emotion/styled-base"));

var _react = _interopRequireDefault(require("react"));

var _logo = _interopRequireDefault(require("../logo"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styledBase["default"])("a", {
  target: "e8hicml0",
  label: "Wrapper"
})("width:70px;height:32px;position:absolute;vertical-align:baseline;left:", function (props) {
  return props.isAlone ? "25px" : "55px";
}, ";top:50%;transform:translateY(-50%);@media (min-width:768px){left:40px;}.logo{width:60px;height:30px;@media (min-width:768px){width:70px;height:30px;}}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL3RvcC1uYXYvbG9nby5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFLd0IiLCJmaWxlIjoiLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvdG9wLW5hdi9sb2dvLmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gXCJyZWFjdFwiO1xyXG5pbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcclxuXHJcbmltcG9ydCBMb2dvSWNvbiBmcm9tIFwiLi4vbG9nb1wiO1xyXG5cclxuY29uc3QgV3JhcHBlciA9IHN0eWxlZC5hYFxyXG4gIHdpZHRoOiA3MHB4O1xyXG4gIGhlaWdodDogMzJweDtcclxuICBwb3NpdGlvbjogYWJzb2x1dGU7XHJcbiAgdmVydGljYWwtYWxpZ246IGJhc2VsaW5lO1xyXG4gIGxlZnQ6ICR7cHJvcHMgPT4gKHByb3BzLmlzQWxvbmUgPyBgMjVweGAgOiBgNTVweGApfTtcclxuICB0b3A6IDUwJTtcclxuICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVkoLTUwJSk7XHJcblxyXG4gIEBtZWRpYSAobWluLXdpZHRoOiA3NjhweCkge1xyXG4gICAgbGVmdDogNDBweDtcclxuICB9XHJcblxyXG4gIC5sb2dvIHtcclxuICAgIHdpZHRoOiA2MHB4O1xyXG4gICAgaGVpZ2h0OiAzMHB4O1xyXG5cclxuICAgIEBtZWRpYSAobWluLXdpZHRoOiA3NjhweCkge1xyXG4gICAgICB3aWR0aDogNzBweDtcclxuICAgICAgaGVpZ2h0OiAzMHB4O1xyXG4gICAgfVxyXG4gIH1cclxuYDtcclxuXHJcbmNvbnN0IExvZ28gPSAoeyB1cmwsIGlzQWxvbmUgfSkgPT4gKFxyXG4gIDxXcmFwcGVyIGhyZWY9e3VybH0gaXNBbG9uZT17aXNBbG9uZX0+XHJcbiAgICA8TG9nb0ljb24gY2xhc3NOYW1lPVwibG9nb1wiIC8+XHJcbiAgPC9XcmFwcGVyPlxyXG4pO1xyXG5cclxuZXhwb3J0IGRlZmF1bHQgTG9nbztcclxuIl19 */"));

var Logo = function Logo(_ref) {
  var url = _ref.url,
      isAlone = _ref.isAlone;
  return _react["default"].createElement(Wrapper, {
    href: url,
    isAlone: isAlone
  }, _react["default"].createElement(_logo["default"], {
    className: "logo"
  }));
};

var _default = Logo;
exports["default"] = _default;